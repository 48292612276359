export default function TryNowCopy() {
  return {
    title: `Let's build your first application`,
    subtitle: `Only takes a few minutes to get started`,
    feedback: {
      title: 'Customer testimonials',
      customers: [
        {
          src: '/customers/logo-dropbox.png',
          feedback:
            'Especially compared to other vendors that Dropbox has worked with in the past, the Apryse engineering support was just very responsive.',
          company: 'Dropbox',
          name: 'Amanda Lansman',
        },
        {
          src: '/customers/logo-ava.png',
          feedback:
            'The support they gave us was consistently very helpful and highly responsive. We hardly had to rely on it due to the quality of the documentation. Apryse proved very easy for our developers to put into our existing codebase.',
          company: 'AVA Industries Ltd',
          name: 'Matthew Henschke',
        },
        {
          src: '/customers/logo-drawboard.png',
          feedback:
            'Apryse demonstrated superior speed and functionality out-of-the-box. In contrast, the competition took a lot of shortcuts, and it was obvious in the user experience.',
          company: 'Drawboard',
          name: 'Alistair Michener',
        },
      ],
    },
    platforms: {
      web: {
        name: 'Web App',
        icon: '/web.svg',
        items: {
          frameworks: [
            {
              text: 'React',
              link: '/web/get-started/react/',
              logo: '/logos/logo-react-color.svg',
            },
            {
              text: 'Angular',
              link: '/web/get-started/angular/new-project/',
              logo: '/logos/logo-angular-color.svg',
            },
            {
              text: 'Vue',
              link: '/web/get-started/vue/',
              logo: '/logos/logo-vue-color.svg',
            },
            {
              text: 'Next.js',
              link: '/web/get-started/nextjs/',
              logo: '/logos/logo-nextjs-color.svg',
            },
            {
              text: 'NuxtJS',
              link: '/web/get-started/nuxt/',
              logo: '/logos/logo-nuxt-color.svg',
            },
            {
              text: 'Svelte',
              link: '/web/get-started/svelte/',
              logo: '/logos/logo-svelte-color.svg',
            },
            {
              text: 'Electron',
              link: '/web/get-started/electron/',
              logo: '/logos/logo-electron-color.svg',
            },
            {
              text: 'Blazor',
              link: '/web/get-started/blazor/new-project/',
              logo: '/logos/logo-blazor-color.svg',
            },
          ],
          languages: [
            {
              text: 'JavaScript',
              link: '/web/get-started/npm/',
              logo: '/logos/logo-javascript-color.svg',
            },
            {
              text: 'TypeScript',
              link: '/web/get-started/typescript/',
              logo: '/logos/logo-typescript-color.svg',
            },
          ],
        },
      },
      mobile: {
        name: 'Mobile App',
        icon: '/mobile.svg',
        items: {
          native: [
            {
              text: 'Android',
              link: '/android/get-started/integration/gradle/',
              logo: '/logos/logo-android-color.svg',
            },
            {
              text: 'iOS',
              link: '/ios/get-started/integration/cocoapods/',
              logo: '/logos/logo-apple-color.svg',
            },
          ],
          frameworks: [
            {
              text: 'React Native',
              link: '/guides/react-native/',
              logo: '/logos/logo-react-color.svg',
            },
            {
              text: 'Xamarin',
              link: '/xamarin/',
              logo: '/logos/logo-xamarin-color.svg',
            },
            {
              text: 'Flutter',
              link: '/guides/flutter/',
              logo: '/logos/logo-flutter-color.svg',
            },
          ],
        },
      },
      server: {
        name: 'Server App',
        icon: '/server.svg',
        items: {
          frameworks: [
            {
              text: 'Node.js',
              link: '/guides/get-started/nodejs/',
              logo: '/logos/logo-nodejs-color.svg',
            },
            {
              text: '.NET',
              link: '/dotnet/get-started/',
              logo: '/logos/logo-dotnet-color.svg',
            },
            {
              text: '.NET Core',
              link: '/dotnetcore/get-started/',
              logo: '/logos/logo-dotNet-core-color.svg',
            },
          ],
          languages: [
            {
              text: 'Java',
              link: '/java/',
              logo: '/logos/logo-java-color.svg',
            },
            {
              text: 'C++',
              link: '/cpp',
              logo: '/logos/logo-cplus-color.svg',
            },
            {
              text: 'Python',
              link: '/python/',
              logo: '/logos/logo-python-color.svg',
            },
            {
              text: 'Go',
              link: '/go/',
              logo: '/logos/logo-go-color.svg',
            },
            {
              text: 'Ruby',
              link: '/ruby/',
              logo: '/logos/logo-ruby-color.svg',
            },
            {
              text: 'PHP',
              link: '/php/',
              logo: '/logos/logo-php-color.svg',
            },
          ],
        },
      },
      lowCode: {
        name: 'Integrations',
        icon: '/lowcode.svg',
        hideTitle: true,
        items: {
          frameworks: [
            {
              text: 'Salesforce',
              link: '/salesforce/',
              logo: '/logos/logo-salesforce-color.svg',
            },
            {
              text: 'Appian',
              link: '/appian/',
              logo: '/logos/logo-appian-color.svg',
            },
            {
              text: 'Mendix',
              link: '/mendix/',
              logo: '/logos/logo-mendix-color.svg',
            },
            {
              text: 'SharePoint',
              link: '/sharepoint/',
              logo: '/logos/logo-sharepoint-color.svg',
            },
            {
              text: 'OutSystems',
              link: '/outsystems/',
              logo: '/logos/logo-outsystems-color.svg',
            },
            {
              text: 'MS Office (Fluent)',
              link: 'https://apryse.com/form/fluent-trial',
              logo: '/logos/logo-fluent.svg',
            },
            {
              text: 'Microsoft Teams',
              link: '/web/get-started/microsoft-teams/',
              logo: '/logos/logo-ms-teams-color.svg',
            },
          ],
        },
      },
      preBuilt: {
        name: 'Pre-built +',
        icon: '/prebuilt.svg',
        hideTitle: true,
        items: {
          frameworks: [
            {
              text: 'Fluent',
              link: 'https://apryse.com/form/fluent-trial',
              logo: '/logos/logo-fluent.svg',
            },
          ],
        },
      },
    },
  };
}
